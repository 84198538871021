import { type standing } from './locationList';

export const chchAccommodation: standing = {
  title: 'Christchurch/Canterbury',
  region: 'Christchurch & Canterbury',
  category: 'Canterbury Accommodation',
  list: [
    "The Musee Christchurch Art Hotel",
    "Wynham Gardens",
    "The Hotel Montreal",
    "The George Hotel",
    "Central City Lockers",
    "Breakfree Hotel -165 Cashel Street",
    "Quest Cambridge Tce",
    "Quest Kilmore St",
    "Hilton Double Tree",
    "Cathedral Junction Christchurch Tram Depot",
    '*Christchurch i-Site',
    'Redwood Hotel',
    'The Jailhouse Accommodation',
    'Mayfair Hotel',
    // 'Omaka Aviation Heritage Centre',
    'He Puna Taimoana',
    '101 Stars Motel',
    '103 Prince of Bealy Motel',
    '11 Ilam Road AirBnB',
    '118 Apartments Sherborne St',
    '123 Motel, Clarence St',
    '130 Racecourse Backpackers',
    '162 Kings on Riccarton',
    '166 Majestic Court Lodge',
    '2 ON Whiteleigh motel',
    '239 ON Lincoln Road Motel',
    '247 Studios on bealey',
    '319 Addington Motel',
    '63 Crandford Motel',
    'AAA Northlands Motel',
    'Aalton Motel',
    'Academy Motel',
    'Achillies Motels',
    'Addington Court Motel',
    'Addington Stadium Motel',
    'Admiral Motel',
    'Airport Delta Motel',
    'Airport Lodge Motel',
    'Airport Palms Motel',
    'Airways Motel',
    'All Stars Backpackers',
    'Alpha Motel',
    'Amalfi Motor Lodge',
    'Amore Motel *',
    'Amross Court Motor Lodge x2',
    'Annabelle Court Motel *',
    'Apollo Motel',
    'Arena Motel',
    'Around the World Backpackers',
    'Arthurs Pass Cafe',
    'Ascot Vale Motor Lodge',
    'Ashford Motor Lodge',
    'Ashleigh Court Motel',
    'Ashley Hotel ( Riccarton Village Inn)',
    'At The Right Place',
    'Athena Motel',
    '136 on Bealey',
    'Avon City Motel',
    'Azena Motel *',
    'Beach Life Apartments',
    'Bealey Avenue Motel',
    'Bealey Plaza Motel',
    'Bella Vista Motel',
    'Annabelle Court Motel',
    'Bush Inn Court Motel',
    'C-Motel CBD',
    'Camelot Motor Lodge',
    'Cosa Hotel',
    'Cashel Court Motel *',
    'Casino Court Motor Lodge',
    'Celtic Backpackers',
    'Central Park Motor Lodge',
    'Centre Point on Colombo',
    'Cherry Tree Lodge *',
    'Christchurch Accommodation',
    'Christchurch City Hotel',
    'Christchurch Motel *',
    'Christchurch Park Motels',
    'City Central Motor Inn',
    'City Centre Motel *',
    'City Worcester Motel',
    'Classique Lodge Motel',
    'Clyde on Riccarton Motel Comfort Inn',
    'Colombo in the City',
    'Colonial Inn Motel',
    'Commodore Airport Hotel',
    'Country Glenn Lodge *',
    'Courtesy Court Motel',
    'Crowne Plaza',
    'Darfield Hotel',
    'Darfield Motel',
    'De lago Motel Apartments',
    'Deans Bush Motel',
    'Diplomat Motel',
    'Quality Hotel Elms',
    'The Elms Hotel',
    'Equestrian Hotel',
    'The Famous Grouse Hotel, Lincoln',
    'Ferrymead Heritage Park',
    'Fino Hotel & Suites',
    'Focus Motel & Executive Suites *',
    'Garden City Motel',
    'Golden Hotel',
    'Gothic Heights Motel *',
    'Hotel Carlton Mill',
    'Heritage Hotel *',
    'Highway cafe Springfield',
    'Homelea Bed & Breakfast',
    'Hotel 115 Worchester StV',
    // 'Ibis Christchurch',
    'International Antarctic Centre',
    'Kauri Motel',
    'Kiwi Basecamp',
    'Rydges Latimer Christchurch',
    'Lincoln Motel',
    'Lorenzo Motor Lodge',
    'Meadowpark Holiday Park (Top 10)',
    'Merivale Appartments',
    'Merivale Manor',
    'Metropolitan Executive Motel Apartments *',
    'Middlepark Motel',
    'Milano Motor Lodge',
    'North South Holiday Park',
    'Northcote Motor Lodge',
    // 'Rainbow Star Homestay & Backpackers',
    'Papanui Court Motel',
    'Peppers Clearwater Resort',
    'Parkview on Hagley',
    'Pavillions Hotel',
    'Pineacres Holiday Park Kaipoi',
    'Point Break Backpackers',
    'Quest Serviced Appartments *',
    'Racecourse Hotel & Motor Lodge',
    'Raceway Motel',
    'Ramada Hotel & Suites',
    'Randolph Motels - Apartments',
    'Rangiora Holiday Park',
    'Rangiora Lodge Motel',
    'Rendezvous Hotel (Marque Hotel)',
    'Riccarton Mall Motel',
    'Riverlands Holiday Park Kaiapoi',
    'Rolleston Highway Motel',
    'Rosewood Court Motel',
    'Rucksackers Backpackers',
    'Salerno Motel Apartments',
    'Sandy Feet Hotel',
    'Sandy Feet Lodge*',
    'Sherborne Motor Lodge *',
    'Smylies YHA Springfield',
    'Southern Comfort Motel x2',
    'Springfield camp ground',
    'Springfield Hotel',
    'Springfield Motel & lodge',
    'Strathern Motor Lodge',
    'Studious Village Backpackers',
    'Sudima Airport Hotel',
    'Sudima Christchurch City *',
    'Sumner Bay Motel',
    'Taste of Kiwi',
    'The Arts Centre',
    'The Towers on the Park',
    'Haka Lodge',
    'Tuscana Motor Lodge',
    'Urbanz Accomodation',
    // 'V Motel',
    'Vita Nova Motel',
    'Novotel City Hotel',
    'Novatel Christchurch Airport',
    'Westside Motor Lodge',
    'Te Pae Christchurch Convention Centre',
    'Distinctions Hotel City',
    'Steviez Café',
    // 'Orari Air bnb',
    // "Eliza's Manor",
    'Wyndham Garden Hotel',

    // end of chch accommodation
  ],
};
